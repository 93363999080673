@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;600;700;800;900;1000&family=Vazirmatn:wght@100;200;300;900&family=Victor+Mono:wght@100;500&display=swap");

body {
  background-color: #FAF7EF !important;
  font-family: "Cairo" !important;

}

.maintitle {
  font-family: "Cairo";
  font-weight: 1000;
  margin-top: 55px;
  text-align: center;
  font-size: 49px;

}

.maintitle-d {
  font-family: "Cairo";
  font-weight: 1000;
  text-align: center;
  font-size: 49px;
  padding-top: 80px ;

}

.secondtext {
  font-family: Cairo;
  font-weight: 800;
  text-align: center;
  color: #D59B07;
  font-size: 42px;
}

.secondtext-d {
  margin-top: 20px;
  font-family: Cairo;
  font-weight: 800;
  text-align: center;
  color: #D59B07;
  font-size: 42px;
}


.allbox0 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 70px;
}

.allbox0-d {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 90px;
}


.allbox01 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}

.allbox001 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 155px;
  margin-bottom: 30px;
}

.allbox001-mob {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 90px;
  margin-bottom: 30px;
}

.title-info-box {
  text-align: center;
  align-content: center;
  align-items: center;
  font-family: Cairo;
  font-weight: 800;
  color: black;
  font-size: 26px;
}
.desc-info-box {
  line-height: 33px;
  font-family: Cairo;
  font-weight: 500;
  text-align: center;
  font-size: 18px;
  color: #545447;
}


/*.contact_container{*/
/*  width: 100%;*/
/*  display: flex;*/
/*  align-content: center;*/
/*  align-items: center !important;*/
/*}*/
.contact__card {
  display: grid;
  align-content: center;
  align-self: center;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem 2rem;
  margin-top: 30px;
  margin-right: 250px;
  margin-left: 250px;
  /* !* Adjust the values for row and column gaps *!*/
}
.contact__card-mob {
  width: 90%;
  display: flex;
  flex-direction: column; /* Set flex direction to column for mobile layout */
  text-align: center;
  /*align-content: center;*/
  align-self: center;
  margin-top: 30px;
  margin-left: 20px;
  margin-right: 20px;
}
/*.contact__card-mob {*/
/*  display: flex;*/
/*  align-content: center;*/
/*  align-self: center;*/
/*  margin-top: 30px;*/
/*  !* !* Adjust the values for row and column gaps *!*!*/
/*}*/

.contact__card-box{
  /*margin-right: 200px;*/
  /*margin-left: 200px;*/
  border: 2px solid #E3DCC9;
  padding: 1.25rem .75rem;
  border-radius: 1rem;
  transition: .3s;
}
.contact__card-box:hover{
  box-shadow: 0 8px 24px hsla(228, 66%, 45%, .1);
}

.allbox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  
}

.text-len{
  width: 350px;
}

.allbox-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  
}



.toptextbox {
  font-family: Cairo;
  font-weight: 900;
  text-align: center;
  font-size: 42px;

}

.bottomtextbox {
  line-height: 33px;
  font-family: Cairo;
  font-weight: 500;
  text-align: center;
  font-size: 18px;
  color: #545447;

}

.bottomtextboxf{
  
  line-height: 33px;
  font-family: Cairo;
  font-weight: 500;
  text-align: center;
  font-size: 18px;
  color: #545447;

}

.box {
  border-left: 1px solid rgb(63, 63, 63);
  width: 100%;
  height: 45px;
  margin: 40px;
  margin-left: 0px;
  margin-right: 0px;
  padding-left: 40px;
  padding-right: 40px;
}

.b1 {
  border: none;
}

.title-left {
  font-family: Cairo;
  font-weight: 800;
  color: black;
  font-size: 36px;

}
.title-info {
  font-family: Cairo;
  font-weight: 800;
  color: black;
  font-size: 26px;

}

.title-dedic {
  font-family: Cairo;
  font-weight: 700;
  line-height: 60px;
  color: black;
  font-size: 34px;

}


.title {
  font-family: Cairo;
  font-weight: 700;
  color: black;
  font-size: 28px;

}


.title-util {
  font-family: Cairo;
  font-weight: 700;
  color: black;
  font-size: 22px;

}

.title-right {
  font-family: Cairo;
  font-weight: 800;
  color: #D59B07;
  font-size: 36px;
}

.stagestext-ylw {
  font-family: Cairo;
  font-weight: 800;
  color: #D59B07;
  font-size: 36px;
}
.stagestext-black {
  font-family: Cairo;
  font-weight: 800;
  color: black;
  font-size: 36px;
}

.stagestext-ylw-mob {
  display: flex;
  align-content: center;
  flex-direction: column;
  text-align: center;
  font-family: Cairo;
  font-weight: 800;
  color: #D59B07;
  font-size: 36px;
}
.stagestext-black-mob {
  display: flex;
  align-content: center;
  flex-direction: column;
  text-align: center;
  margin-top: 20px;
  font-family: Cairo;
  font-weight: 800;
  color: black;
  font-size: 36px;
}




p {
  margin: 3px;

}

.button1 {

  
  justify-content: center;
  align-items: center;
  background-color: #322F28;
  color: white;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  font-size: 20px;
  margin: 20px;
  border: 0;
  border-radius: 80px;
}






.name-text {
  font-family: Cairo;
  font-weight: 800;
  
  text-align: center;
  font-size: 20px;


}

.role-text {
  font-family: Cairo;
  font-weight: 500;
  text-align: center;
  font-size: 18px;
}

.c1 {
  background-color: #d6cf0F;
}

.c2 {
  background-color: #3a677c;
}

.c3 {
  background-color: #383e7c;

}

.c4 {

  background-color: #67CAAB;
}

.c5 {
  background-color: #899B8D;

}

.c6 {
  background-color: #5FBCDD;

}




.circle {
  margin: 20px;
  height: 160px;
  width: 160px;
  border-radius: 50%;

}

.circle-img1 {

  width: 170px;

  border-radius: 0% 0% 60% 50%;
  position: relative;

  transform: translateY(-16%);
}

.circle-img2 {

  width: 160px;
  border-radius: 0% 0% 48% 50%;
  position: relative;

  transform: translateY(-2%);
}

.circle-img3 {

  width: 170px;
  border-radius: 0% 0% 53% 44%;
  position: relative;

  transform: translateY(-9%);
}

.circle-img4 {

  width: 170px;
  border-radius: 0% 0% 54% 39%;
  position: relative;
  transform: translateY(-12%);

}

.circle-img5 {

  width: 180px;
  border-radius: 0% 0% 49% 48%;
  position: relative;

  transform: translateY(-29%) translateX(-5%);
}

.circle-img6 {

  width: 180px;
  border-radius: 0% 0% 49% 47%;
  position: relative;

  transform: translateY(-33%) translateX(-5%);
}

.social-media {
  width: 40px;
  height: 40px;
  background-color: #322F28;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;

}

.image-insta {
  margin-right: .1rem;
}

.image-twit {
  margin-left: .1rem;
}

.total {
  width: 85%;
  min-width: 400px;
  padding-left: 1px;
  padding-right: 1px;
}

.total-info {
  max-width: 90%;
  width: auto;
  padding-left: 1px;
  padding-right: 1px;
}
.total-info-m {
  max-width: 88%;
  width: auto;
  padding-left: 1px;
  padding-right: 1px;
}
.top-title{
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 20px;
  padding: 10px;
  padding-right: 30px;
}
.top-title1{
  display: flex;
  justify-content: space-around;
  width: 50%;
  margin: 20px;
  padding: 10px;
  padding-right: 30px;
}
.top{
  background-color: #FAF7EF;
  margin-right: 0;
  position: fixed;
  width: 100%;
}

.menu-btn{
  color: #202017;
  width: 95%;
  margin: 10px;
  margin-right: 0px;
  text-align: left;
  background-color: #FAF7EF;
  border: 0;
}
.button-bergur{
  background-color: #ddd8cc !important;
}

.bg-btn{
  background-color: #F3EFE5 !important;
}

.total-info1 { 
  width: 90%; 
  margin-top: 20px; 
  margin-bottom: 60px; 
}
.bg-d{
  width:600px;
  margin-top: 80px;
  margin-left: 5px;
  height: 76%;
  margin-right: 8px;
  background-image: url("./Images/Group\ 1.png");
  background-size: 810px;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.07;
  position: absolute;
  -webkit-mask:radial-gradient(circle , #fff 50% ,transparent);
  z-index: -1;
  
}
.bg-di{
  width:1200px;
  margin-top: 100px;
  margin-left: 3px;
  height: 55%;
  margin-right: 18px;
  background-image: url("./Images/Group\ 1.png");
  background-size: 800px;
  background-repeat:repeat;
  background-position: center;
  opacity: 0.05;
  position: absolute;
  -webkit-mask:radial-gradient(circle , #fff 20% ,transparent);
  z-index: -1;
  
}
.bg-m{
  width: 400px;;
  margin-top: 50px;
  height: 740px;
  background-image: url("./Images/Group\ 1.png");
  background-size: 1100px;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.04;
  position: absolute;
  -webkit-mask-image: linear-gradient(to top, transparent 30%, white 40%);
  /*-webkit-mask-image: linear-gradient(circle, transparent 30%, white 80%);*/
  /*-webkit-mask:radial-gradient(circle , #fff 80%,black 10%);*/
  z-index: -1;
}
.bg-mi{
  width: 400px;;
  margin-top: 20px;
  height: 680px;

  background-image: url("./Images/Group\ 1.png");
  background-size: 1000px;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.05;
  position: absolute;
  -webkit-mask:radial-gradient(circle , #fff 80% ,transparent);
  z-index: -1;
  
}
.footer-m{
  margin-left: 50px;
  margin-right: 50px;

}
.color-fukaff{
  color:#D59B07
}

.title-size{
  font-size: 20px !important;
}